<template>
    <v-layout align-center column fill-height justify-center>
        <v-icon color="warning" x-large>warning</v-icon>
        <v-flex headline mx-2 shrink v-text="$t('sa.support.no_action_selected')" />
    </v-layout>
</template>

<script>
export default {
    name: 'NoActionSelected'
}
</script>